const marketCategoryActions = (): void => {
    const $rows = grp.jQuery('input.action-select');
    const $label = grp.jQuery('<span/>')
        .addClass('grp-button grp-button-state-inactive')
        .appendTo('li.grp-action-counter').hide();

    function updateCounter(): void {
        let count = 0;

        $rows.filter(':checked').each(function (this: HTMLInputElement) {
            const $input = grp.jQuery(this).parent().parent().find('td.field-offer_cnt');
            const val = parseInt($input.html(), 10);
            if (val) {
                count += val;
            }
        });

        if (count) {
            $label.html('Выбрано товаров: ' + count).show();
        } else {
            $label.hide();
        }
    }

    $rows.each(function (this: HTMLInputElement) {
        grp.jQuery(this).on('click', updateCounter);
    });

    grp.jQuery('#action-toggle').on('click', updateCounter);
};

export { marketCategoryActions };
