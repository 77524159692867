import Typograf from 'components/typograf';

class DoskaPostForm {
    private readonly isActiveField: HTMLInputElement;
    private readonly flagsField: HTMLInputElement;
    private readonly titleField: HTMLInputElement;
    private readonly textField: HTMLTextAreaElement;
    private readonly typograf: Typograf;

    constructor() {
        this.typograf = new Typograf({
            locale: ['ru', 'en-US'],
            enableRule: [
                'common/html/stripTags',
                'common/space/delLeadingBlanks'
            ],
            disableRule: [
                'common/number/fraction',
                'ru/date/fromISO'
            ]
        });

        this.isActiveField = document.getElementById('id_is_active') as HTMLInputElement;
        this.isActiveField.onchange = this.isActiveChecked;

        this.flagsField = document.getElementById('id_flags_0') as HTMLInputElement;
        this.flagsField.onchange = this.flagsChecked;

        this.titleField = document.getElementById('id_title') as HTMLInputElement;
        this.titleField.onblur = this.typografExecute;
        this.titleField.onfocus = this.typografExecute;

        this.textField = document.getElementById('id_text') as HTMLTextAreaElement;
        this.textField.onblur = this.typografExecute;
        this.textField.onfocus = this.typografExecute;
    }

    public static init(): DoskaPostForm | void {
        return new DoskaPostForm();
    }

    private typografExecute = (e): void => {
        e.currentTarget.value = this.typograf.execute(e.currentTarget.value);
    };

    private isActiveChecked = (e): void => {
        if (e.target.checked) {
            this.flagsField.checked = false;
        }
    };
    private flagsChecked = (e): void => {
        if (e.target.value === 'moderate_rejected' && e.target.checked) {
            this.isActiveField.checked = false;
        }
    };
}

export { DoskaPostForm };
