import { typografInputField } from 'forms';

const publicationsArticleForm = (formId: string): void => {
    window.addEventListener('DOMContentLoaded', () => {
        const form: HTMLFormElement | null = document.getElementById(formId) as HTMLFormElement | null;
        if (!form) {
            throw 'Форма не найдена: ' + formId;
        }

        const pinDateRow: HTMLDivElement | null = document.querySelector('.form-row.pin_date');
        if (!pinDateRow) {
            throw 'Поле не найдено: Закрепить на срок';
        }

        const pinImageRow: HTMLDivElement | null = document.querySelector('.form-row.pin_image');
        if (!pinImageRow) {
            throw 'Поле не найдено: Изображение к закреплённой статье';
        }

        const commentRow: HTMLDivElement | null = document.querySelector('.form-row.comment');
        if (!commentRow) {
            throw 'Поле не найдено: Комментарий модератора';
        }

        const pinShow = (): void => {
            form.pin_date.labels.item(0).classList.add('required');
            form.pin_image.labels.item(0).classList.add('required');
            form.pin_date.required = form.pin_image.required = true;
            pinDateRow.style.display = pinImageRow.style.display = 'block';
        };

        const pinHide = (): void => {
            form.pin_date.required = form.pin_image.required = false;
            pinDateRow.style.display = pinImageRow.style.display = 'none';
        };

        form.pin.onchange = (): void => {
            form.pin.checked ? pinShow() : pinHide();
        };

        form.onsubmit = (e) => {
            if (e.submitter instanceof HTMLInputElement && e.submitter.name === '_fsmtransition-state-reject') {
                if (!form.comment.value.trim().length) {
                    e.preventDefault();
                    commentRow.classList.add('grp-errors');
                    form.comment.placeholder = 'Необходимо указать причину отказа в размещении статьи';
                    form.comment.focus();
                }
            }

            if (form.pin.checked) {
                if (!form.pin_date.value.length) {
                    e.preventDefault();
                    pinDateRow.classList.add('grp-errors');
                    form.pin_date.focus();
                }
                if (!form.pin_image.value.length) {
                    e.preventDefault();
                    pinImageRow.classList.add('grp-errors');
                    form.pin_image.focus();
                }
            }
        };

        typografInputField('id_title');
        typografInputField('id_tizer');

        form.pin.onchange();
    });
};

export { publicationsArticleForm };
