const usersUserForm = (formId: string): void => {
    const form: HTMLFormElement | null = document.getElementById(formId) as HTMLFormElement | null;
    if (!form) {
        throw 'Форма не найдена: ' + formId;
    }

    const reason: HTMLDivElement | null = document.querySelector('.not_active_reason');
    if (!reason) {
        throw 'Поле не найдено: Причина блокировки';
    }

    const comment: HTMLDivElement | null = document.querySelector('.not_active_comment');
    if (!comment) {
        throw 'Поле не найдено: Коммертарий по блокировке';
    }

    const moderator: HTMLDivElement | null = document.querySelector('.not_active_moderator');
    if (!moderator) {
        throw 'Поле не найдено: Заблокировал';
    }

    if (form.is_active.checked) {
        form.not_active_reason.required = false;
        reason.style.display = comment.style.display = moderator.style.display = 'none';
    } else {
        form.not_active_reason.required = true;
        form.not_active_reason.labels.item(0).classList.add('required');
    }

    form.addEventListener('submit', function (this: HTMLFormElement, e): void {
        if (this.is_active.checked) {
            form.not_active_reason.value = '';
            form.not_active_comment.value = '';
        } else if (this.not_active_reason.value === '') {
            reason.classList.add('grp-errors');
            e.preventDefault();
        }
    });

    form.is_active.addEventListener('change', function (this: HTMLInputElement) {
        reason.style.display = comment.style.display = moderator.style.display = this.checked ? 'none' : 'block';

        if (!this.checked && !form.not_active_comment.value.length) {
            const today = new Date();
            form.not_active_comment.value = 'Статус изменён: ' + today.toLocaleString() + '\n';
        }
    });
};

export { usersUserForm, };
