import * as forms from './forms';

import './datepicker';
import './styles/default.scss';

export const ElecAdmin = {
    Catalog: {
        BrandForm: forms.catalogBrandForm,
        CompanyForm: forms.catalogCompanyForm,
    },
    Doska: {
        PostForm: forms.DoskaPostForm
    },
    Market: {
        CategoryActions: (): void => {
            window.addEventListener('DOMContentLoaded', forms.marketCategoryActions);
        },
        CategoryForm : forms.MarketCategoryForm,
        OfferForm : forms.marketOfferForm,
    },
    News: {
        NewsForm: forms.newsNewsForm,
    },
    Publications: {
        ArticleForm: forms.publicationsArticleForm
    },
    Users: {
        UserForm: (formId: string): void => {
            window.addEventListener('DOMContentLoaded', (): void => {
                forms.usersUserForm(formId);
            });
        }
    },
    DropzoneUploadForm: (formId: string): void => {
        window.addEventListener('DOMContentLoaded', (): void =>{
            forms.dropzoneUploadForm(formId);
        });
    }
};

declare global {
    interface Window {
        ElecAdmin: typeof ElecAdmin;
    }
}
