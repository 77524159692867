import { typografInputField } from 'forms';

const newsNewsForm = (formId: string): void => {
    window.addEventListener('DOMContentLoaded', () => {
        const form: HTMLFormElement | null = document.getElementById(formId) as HTMLFormElement | null;
        if (!form) {
            throw 'Форма не найдена: ' + formId;
        }

        const commentRow: HTMLDivElement | null = document.querySelector('.form-row.comment');
        if (!commentRow) {
            throw 'Поле не найдено: Комментарий модератора';
        }

        form.onsubmit = (e) => {
            if (e.submitter instanceof HTMLInputElement && e.submitter.name === '_fsmtransition-state-reject') {
                if (!form.comment.value.trim().length) {
                    e.preventDefault();
                    commentRow.classList.add('grp-errors');
                    form.comment.placeholder = 'Необходимо указать причину отказа в размещении статьи';
                    form.comment.focus();
                }
            }
        };

        typografInputField('id_title');
        typografInputField('id_tizer');
    });
};

export { newsNewsForm };
