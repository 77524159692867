import Dropzone from 'dropzone';

const dropzoneUploadForm = function (fieldId: string): void {
    const editor = document.getElementById(fieldId) as HTMLTextAreaElement | null;

    if (editor === null) {
        return;
    }

    new Dropzone(editor, {
        url: '/dropzone_upload/',
        createImageThumbnails: false,
        clickable: false,
        acceptedFiles: 'image/png,image/jpeg,image/gif,image/webp',
        success: (file): void => {
            if (file.xhr) {
                if (editor.selectionStart || editor.selectionStart === 0) {
                    const startPos = editor.selectionStart;
                    const endPos = editor.selectionEnd;
                    editor.value = editor.value.substring(0, startPos)
                        + file.xhr.responseText + '\n'
                        + editor.value.substring(endPos, editor.value.length);
                } else {
                    editor.value += '\n' + file.xhr.responseText;
                }
            }
        }
    });
};

export { dropzoneUploadForm };
